<template>
<div>
    <button @click="Cancel()" class="btn btn-link redirect-home">
        <b-img width="11" height="9" :src="closeFilterIcon" /> Back to requests</button>
    <div class="page-details row custom-card">
        <div class="text-center text-danger col-lg-12" v-if="IsDataLoaded&&!IsApproveOrRejectEnabled">The Request is already approved or rejected.</div>
        <div class="col-lg-12 approve-only-section" v-if="IsDataLoaded">
            <div class="row top-section">
                <h4 class="col-lg-3 card-main-title">Request Review &amp; Approval</h4>
                <div class="col-lg-5 offset-lg-2">
                    <div class="row">
                        <div class="col-lg-8">
                            <b-form-group v-if="IsCFOApprovalRequired" label-cols-sm="6" class="custom-radio" label="Request CFO Approval:">
                                <b-form-radio-group size="sm" id="CFO-Approval-Required" v-model="Request.IsCFOApprovalRequired">
                                    <b-form-radio value="Yes">Yes</b-form-radio>
                                    <b-form-radio value="No">No</b-form-radio>
                                </b-form-radio-group>
                            </b-form-group>
                        </div>
                        <div class="col-lg-4">
                            <button v-if="canEditRequest" @click="EditRequest()" class="text-uppercase btn btn-secondary btn-primary">
                                Edit Request
                            </button>
                        </div>
                    </div>
                </div>

                <div>
                    <div class="transaction-number">
                        <p class="transaction-label">Transaction ID Number</p>
                        <p class="transaction-id">{{Request.ID}}</p>
                    </div>
                </div>
            </div>
            <div class="row top-section">
                <div class="col-lg-4">
                    <p class="review-text"><span class="review-label">Requested by:</span> {{Request.Requestor|formatEmailToUserName}}</p>
                </div>
                <div class="col-lg-4">
                    <p class="review-text"><span class="review-label">Requested on:</span> {{Request.CreatedDate|formatDate}}</p>
                </div>
            </div>
        </div>
        <wiretransfer-overview class="col-lg-9" :Request="Request" :ID="id"></wiretransfer-overview>
        <div class="col-lg-3 approval-side-section">
            <div class="row">
                <p class="approve-reject-label"> Approve / Reject Wire Transfer Request</p>
                <p class="approve-reject-content">
                    Please review the submitted request, answer the following questions and approve or deny the request.
                </p>
                <b-form-group class="custom-radio" label="Is this a new vendor?">
                    <b-form-radio-group size="sm" id="new-vendor" v-model="ApprovalDetails.NewVendor">
                        <b-form-radio value="Yes">Yes</b-form-radio>
                        <b-form-radio value="No">No</b-form-radio>
                    </b-form-radio-group>
                </b-form-group>
                <b-form-group v-if="IsNewVendorRequest" class="custom-radio" label="Did you verbally confirm the banking info?">
                    <b-form-radio-group size="sm" id="banking-details-confirmed" v-model="ApprovalDetails.VerballyConfirmedBankingInformation">
                        <b-form-radio value="Yes">Yes</b-form-radio>
                        <b-form-radio value="No">No</b-form-radio>
                    </b-form-radio-group>
                </b-form-group>
                <b-form-group class="custom-radio" label="Banking Info Changed?" v-if="IsNotNewVendorRequest">
                    <b-form-radio-group size="sm" id="banking-info-changed" v-model="ApprovalDetails.BankingInfoChanged">
                        <b-form-radio value="Yes">Yes</b-form-radio>
                        <b-form-radio value="No">No</b-form-radio>
                    </b-form-radio-group>
                </b-form-group>

                <b-form-group v-if="IsVendorDetailsNeeded" class="col-lg-12" label="First Name">
                    <b-form-input :maxlength="50" placeholder="Enter First Name" v-model="ApprovalDetails.FirstName"></b-form-input>
                </b-form-group>
                <b-form-group v-if="IsVendorDetailsNeeded" class="col-lg-12" label="Last Name">
                    <b-form-input :maxlength="50" placeholder="Enter Last Name" v-model="ApprovalDetails.LastName"></b-form-input>
                </b-form-group>
                <b-form-group v-if="IsVendorDetailsNeeded" class="col-lg-12" label="Title">
                    <b-form-input :maxlength="50" placeholder="Enter Title" v-model="ApprovalDetails.Title"></b-form-input>
                </b-form-group>
                <b-form-group v-if="IsVendorDetailsNeeded" class="col-lg-12" label="Email">
                    <b-form-input :maxlength="50" placeholder="Enter Email" v-model="ApprovalDetails.Email"></b-form-input>
                </b-form-group>
                <b-form-group v-if="IsVendorDetailsNeeded" class="col-lg-12" label="Phone Number">
                    <b-form-input :maxlength="13" placeholder="Enter Phone Number" v-model="ApprovalDetails.PhoneNumber"></b-form-input>
                </b-form-group>
                <b-form-group v-if="IsNewVendorRequest">
                    <label class="attach-label">Please attach all new vendor related docs!</label>
                    <vue-dropzone :duplicateCheck="true" ref="myVueDropzone" id="dropzone" :useCustomSlot="true" class="customdropzone" :include-styling="false" :options="dropzoneOptions" v-on:vdropzone-file-added="UploadedToDropzone" v-on:vdropzone-removed-file="RemovedFromDropzone">
                        <div class="dropzone-custom-content">
                            <p class="Drag-and-drop-file-h">
                                <b-img style="margin-top: -7px !important; font-family: 'Open Sans'" />
                                Drag and drop file here or
                                <span class="draganddropspan"> browse </span>
                            </p>
                        </div>
                    </vue-dropzone>
                </b-form-group>
                <b-form-group v-if="IsCFOApproval">
                    <label class="attach-label">Additional Documentation Requested by CFO</label>
                    <vue-dropzone :duplicateCheck="true" ref="myVueCfoDocDropzone" id="cfoDocDropzone" :useCustomSlot="true" class="customdropzone" :include-styling="false" 
                    :options="dropzoneOptions" v-on:vdropzone-file-added="UploadedToCfoDocDropzone" v-on:vdropzone-removed-file="RemovedFromCfoDocDropzone">
                        <div class="dropzone-custom-content">
                            <p class="Drag-and-drop-file-h">
                                <b-img style="margin-top: -7px !important; font-family: 'Open Sans'" />
                                Drag and drop file here or
                                <span class="draganddropspan"> browse </span>
                            </p>
                        </div>
                    </vue-dropzone>
                </b-form-group>
                <b-form-group>
                    <label style="font-size: 14px;text-transform:none;font-weight: normal;font-stretch: normal;font-style: normal;line-height: 1.43;letter-spacing: normal;color: #4d5161;">Please provide comments if holding for review & need more details, or include CFO’s approval details</label>
                    <b-textarea class="notes-fixed-height" v-model="ApprovalDetails.Comments" placeholder="Add Comments" label="Notes" :rows="5">

                    </b-textarea>
                    <div class="error-message-format required-field" v-if="!$v.ApprovalDetails.Comments.required&& $v.ApprovalDetails.Comments.$dirty">
                        Required field
                    </div>
                </b-form-group>
                <div class="col-lg-12 text-center btn-stacked" v-if="IsCFOApprovalRequired">
                    <button @click="HoldForReview()" v-if="IsCFOApprovalRequired" class="btn custom-button btn-link">
                        Hold For Review
                    </button>
                </div>
                <div class="col-lg-12 text-center btn-stacked" v-if="!IsCFOApproval && !IsCFOApproved">
                    <button @click="Reject()" class="text-uppercase btn btn-secondary btn-primary">
                        Deny Request
                    </button>
                </div>
                <div class="col-lg-12 text-center btn-stacked" v-if="!IsCFOApproval">
                    <button v-if="$v.$invalid" disabled class="btn custom-button btn-primary">
                        Approve Request
                    </button>
                    <button v-else class="btn custom-button btn-primary" @click="Approve()">
                        Approve Request
                    </button>
                </div>
                <div class="col-lg-12 text-center btn-stacked" v-if="IsCFOApproval">
                    <button v-if="$v.$invalid" disabled class="btn custom-button btn-primary">
                        Request CFO Approval
                    </button>
                    <button v-else @click="RequestCFOApproval()" class="btn custom-button btn-primary">
                        Request CFO Approval
                    </button>
                </div>

            </div>

        </div>
    </div>
</div>
</template>

<script>
import store from "@/store";
import authenticate from "../../mixins/wiretransferworkflowvauthorization";
const moment = require("moment");
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import {mapGetters} from 'vuex';
import {
    requiredIf,
    required,
    // minLength
} from "vuelidate/lib/validators";
export default {
    components: {
        vueDropzone: vue2Dropzone
    },
    validations: {
        Request: {
            DebitAccountNumber: {
                required
            }
        },
        ApprovalDetails: {
            NewVendor: {
                required
            },
            VerballyConfirmedBankingInformation: {
                required: requiredIf(function (model) {
                    return model.NewVendor == 'Yes';
                })
            },
            BankingInfoChanged: {
                required: requiredIf(function (model) {
                    return model.NewVendor != 'Yes';
                })
            },
            FirstName: {
                required: requiredIf(function () {
                    return this.IsVendorDetailsNeeded;
                })
            },
            LastName: {
                required: requiredIf(function () {
                    return this.IsVendorDetailsNeeded;
                })
            },
            Email: {
                required: requiredIf(function () {
                    return this.IsVendorDetailsNeeded;
                })
            },
            PhoneNumber: {
                required: requiredIf(function () {
                    return this.IsVendorDetailsNeeded;
                })
            },
            Title: {
                required: requiredIf(function () {
                    return this.IsVendorDetailsNeeded;
                })
            },
            Comments: {
                required: requiredIf(function () {
                    return this.RejectComments == true;
                })
            },

        }
    },
    name: "WireTransferApproveLevel1",
    mixins: [authenticate],
    data() {
        return {
            closeFilterIcon: require("@/assets/Icons/close-filter-icon.svg"),
            Request: {
                DebitAccountNumber: null
            },
            Error: false,
            ShowRejectModal: false,
            Comments: "",
            HideFooterButtons: true,
            RejectComments: false,
            AgencyList: [],
            ApprovalDetails: {
                NewVendor: null,
                VerballyConfirmedBankingInformation: null,
                FirstName: null,
                LastName: null,
                Title: null,
                Email: null,
                PhoneNumber: null,
                Comments: null,
                BankingInfoChanged: null
            },
            dropzoneOptions: {
                closeFilterIcon: require("@/assets/Icons/file.png"),
                previewTemplate: this.template(),
                url: `${process.env.VUE_APP_API_ROOT}api/v1/WireTransfer/Upload`,
                uploadMultiple: true,
                maxFiles: 20,
                minFiles: 1,
                maxFilesize: 20,
                addRemoveLinks: false,
                parallelUploads: 20,
                createImageThumbnails: false,
                autoProcessQueue: false,
                init: function () {
                    this.on("addedfile", function (file) {
                        if (this.files.length > 20) {
                            this.removeFile(file);
                            window.alert("Maximum File Upload limit is 20!");
                        }
                    });
                },
            },

        };
    },
    filters: {
        formatDate(date) {
            return moment(date).isValid() ? moment(date).format("MM/DD/YYYY") : "-";
        },
        formatEmailToUserName(email) {
            if (email.indexOf("@") > -1) {
                let name = email.substring(0, email.indexOf("@"));
                let values = name.split(".");
                name = "";
                for (var i = 0; i < values.length; i++) {
                    let val = values[i];
                    name += " " + val.substring(0, 1).toUpperCase() + val.substring(1, val.length);
                }
                return name;
            } else {
                return email;
            }
        }
    },
    props: ["id"],
    watch: {
        "Request": function () {
            if (this.Request.WireTransferRequestWorkflowDetail != null) {
                this.ApprovalDetails = this.Request.WireTransferRequestWorkflowDetail;
            }
            if (!this.UserAgency(this.Request.Agency) || !this.canApproveLevel1WireTransferRequest) {
                this.$router.push({
                    name: "error",
                    params: {
                        message: "UnAuthorized"
                    },
                });
            }

        }
    },
    computed: {
        ...mapGetters({
                loggedInUser: "emailAddress"
            }),
        canEditRequest() {
            return this.Request.Requestor == this.loggedInUser && this.Request.TaskStatus == "Requested";
        },
        IsCFOApproved()
        {
         return this.Request.TaskStatus == "CFO Approved";
        },
        IsCFOApprovalRequired() {
            return this.Request.TaskStatus == "Requested";
        },
        IsNewVendorRequest() {
            return this.ApprovalDetails.NewVendor == 'Yes';
        },
        IsNotNewVendorRequest() {
            return this.ApprovalDetails.NewVendor == 'No';
        },
        IsVerballyConfirmedTheBankingInfo() {
            return this.ApprovalDetails.VerballyConfirmedBankingInformation == 'Yes';
        },
        IsBankingInfoChanged() {
            return this.ApprovalDetails.BankingInfoChanged == 'Yes';
        },
        IsVendorDetailsNeeded() {
            return (this.IsNewVendorRequest && this.IsVerballyConfirmedTheBankingInfo) || (this.IsBankingInfoChanged && !this.IsNewVendorRequest);
        },
        RequestedCode() {
            return this.Request ? this.Request.ProductCode : "";
        },
        ModalTitle() {
            return `Reject Request ${this.ClientName}`;
        },
        canApproveCurrentWorkflowRequest() {
            return this.$store.getters.canApproveLevel1WireTransferRequest;
        },
        IsApproveOrRejectEnabled() {
            return true; // this.Request&&this.Request.TaskStatus==status.ApproveLevel1;
        },
        IsDataLoaded() {
            return this.Request && this.Request.PaymentType != null ? true : false;
        },
        IsCFOApproval() {
            return this.Request && this.Request.IsCFOApprovalRequired == 'Yes';
        },
        canApproveLevel1WireTransferRequest() {
            return this.$store.getters.canApproveLevel1WireTransferRequest;
        },
        userDataMapping() {
            return this.$store.getters.dataMappings;
        },
        AllAgencyAccess() {
            return this.userDataMapping != null && this.userDataMapping.cdc839a5c1db4c43bb5b0e180cf0270e == "*";
        },
        IsAgencyArray() {
            return this.userDataMapping != null && !this.AllAgencyAccess && Array.isArray(this.userDataMapping.cdc839a5c1db4c43bb5b0e180cf0270e);
        },
    },
    methods: {
        UserAgency(agency) {
            return this.AllAgencyAccess || (this.IsAgencyArray ? this.userDataMapping.cdc839a5c1db4c43bb5b0e180cf0270e.findIndex(d => d == agency) > -1 : false);
        },
        EditRequest() {
            this.$router.push({
                name: 'Wire Transfer',
                params: {
                    id: this.Request.ID
                }
            })
        },
        UploadedToDropzone() {
            this.$set(this.WireTransfer, "Files", this.$refs.myVueDropzone.dropzone.files);
        },
        RemovedFromDropzone() {
            this.$set(this.WireTransfer, "Files", this.$refs.myVueDropzone.dropzone.files);
        },
        UploadedToCfoDocDropzone() {
            this.$set(this.WireTransfer, "CFODocuments", this.$refs.myVueCfoDocDropzone.dropzone.files);
        },
        RemovedFromCfoDocDropzone() {
            this.$set(this.WireTransfer, "CFODocuments", this.$refs.myVueCfoDocDropzone.dropzone.files);
        },
        template: function () {
            return `<div class="dz-preview dz-file-preview" style='background-color:#ffffff !important;display:flex;'>

                <div class="dz-details" style='margin-right:30px !important;'>
                    <div class="dz-size" style="display:none;" ><span data-dz-size></span></div>
                    <div class="FileforNewClientxls-Copy" style="margin-left:20px;width:270px;cursor:default !important;" ><span style='word-break:break-all !important;font-size:14px !important;font-weight:400;font-stretch:normal;font-style: normal;line-height: 0.71;color: #00a1d2 !important; font-family:"Open Sans" !important;' data-dz-name></span></div>
                </div>
                <a>
                 <img data-dz-remove=""  style='' src='/icons/fileremove.svg'  @click="DeleteServerFile()" ></img>
                </a>
            </div>`;
        },
        SetDirty() {
            this.$v.Comments.$touch();
        },
        ResetDirty() {
            this.$v.Comments.$reset();
        },
        GetData() {
            return {
                ID: parseInt(this.id),
                Comments: this.ApprovalDetails.Comments,
                ActiveTaskID: this.Request.ActiveTaskID | 0,
                ProjectID: this.Request.OmniflowProjectID | 0,
                ProcessID: this.Request.OmniflowProcessID | 0,
                // Files:this.$refs.myVueDropzone.dropzone.files,
                WireTransferRequestWorkflowDetail: this.ApprovalDetails,
                DebitAccountNumber: this.Request.DebitAccountNumber
            };
        },
        ShowReject() {
            this.ShowRejectModal = true;
        },
        CancelReject() {
            this.ShowRejectModal = false;
        },
        Reject() {
            this.ShowRejectModal = false;
            this.RejectComments = true;
            this.$v.ApprovalDetails.Comments.$touch();
            if (!this.$v.ApprovalDetails.Comments.$invalid) {
                this.$store
                    .dispatch("wiretransfer/RejectRequestLevel1", this.GetData())
                    .then(() => {
                        store.dispatch(
                            "progress/setSuccessMessage",
                            `Wire transfer has been rejected`
                        );
                        this.$router.push("/WireTransfers");
                    })
                    .catch((ex) => {
                        if (ex.response) {
                            this.SetErrorMessage(
                                "Error while rejecting request",
                                ex.response.data
                            );
                        } else {
                            this.SetErrorMessage("Error while rejecting request", ex);
                        }
                    });
            }
        },
        Approve() {
            this.ShowRejectModal = false;
            this.RejectComments = false;
            let formData = new FormData();
            formData.append('action', JSON.stringify(this.GetData()));
            if (this.$refs.myVueDropzone && this.$refs.myVueDropzone.dropzone && this.$refs.myVueDropzone.dropzone.files) {
                let files = this.$refs.myVueDropzone.dropzone.files;
                for (var i = 0; i < files.length; i++) {
                    let file = files[i];

                    formData.append('files[' + i + ']', file);
                }
            }
            this.$store
                .dispatch("wiretransfer/ApproveRequestLevel1", formData)
                .then(() => {
                    store.dispatch(
                        "progress/setSuccessMessage",
                        `Wire transfer request has been approved`
                    );
                    this.$router.push("/WireTransfers");
                })
                .catch((ex) => {
                    if (ex.response) {
                        this.SetErrorMessage(
                            "Error while approving request",
                            ex.response.data
                        );
                    } else {
                        this.SetErrorMessage("Error while approving request", ex);
                    }
                });
        },
        RequestCFOApproval() {
            this.ShowRejectModal = false;
            this.RejectComments = false;
            let formData = new FormData();
            formData.append('action', JSON.stringify(this.GetData()));
            if (this.$refs.myVueCfoDocDropzone && this.$refs.myVueCfoDocDropzone.dropzone && this.$refs.myVueCfoDocDropzone.dropzone.files) {
                let files = this.$refs.myVueCfoDocDropzone.dropzone.files;
                for (var i = 0; i < files.length; i++) {
                    let file = files[i];
                    formData.append('CFODocuments', file);
                }
            }            
            this.$store
                .dispatch("wiretransfer/RequestCFOApproval", formData)
                .then(() => {
                    store.dispatch(
                        "progress/setSuccessMessage",
                        `Wire transfer request has sent to CFO approval`
                    );
                    this.$router.push("/WireTransfers");
                })
                .catch((ex) => {
                    if (ex.response) {
                        this.SetErrorMessage(
                            "Error while approving request",
                            ex.response.data
                        );
                    } else {
                        this.SetErrorMessage("Error while sending request to CFO", ex);
                    }
                });
        },

        HoldForReview() {
            this.ShowRejectModal = false;
            this.RejectComments = false;
            this.$store
                .dispatch("wiretransfer/HoldRequestForReview", this.GetData())
                .then(() => {
                    store.dispatch(
                        "progress/setSuccessMessage",
                        `Wire transfer request has been put on hold`
                    );
                    this.$router.push("/WireTransfers");
                })
                .catch((ex) => {
                    if (ex.response) {
                        this.SetErrorMessage(
                            "Error while holding the request for review",
                            ex.response.data
                        );
                    } else {
                        this.SetErrorMessage("Error while holding the request for review", ex);
                    }
                });
        },
        SetErrorMessage(message, ex) {
            store.dispatch(
                "progress/setErrorMessage",
                `${message}. The error is ${ex}`
            );
        },
        Cancel() {
            this.$router.push("/WireTransfers");
        },
    },
};
</script>

<style scoped>
.customdropzone {
    min-height: 80px !important;
    border-radius: 8px !important;
    border: dashed 2px #e9eef0 !important;
    background-color: #ffffff !important;
    cursor: pointer !important;
}

.Drag-and-drop-file-h {
    /* padding-top: 4px;
    padding-left: 20px; */
    margin-left: 10px !important;
    margin-top: 30px !important;
    /*width: 387px !important;
    height: 120px !important; */
    font-family: "Open Sans" !important;
    font-size: 14px !important;
    font-weight: normal !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 0.71 !important;
    letter-spacing: normal !important;
    /* position: absolute !important; */

    /* margin-top: 14px !important; */
    /* left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); */
    /* color: #a2a9ad !important; */
}

.attach-label {
    line-height: 1;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: italic;
    line-height: normal;
    letter-spacing: normal;
    color: #4d5161;
}

.customdropzone.dz-started .Drag-and-drop-file-h {
    margin-top: 14px !important;
}

.approval-side-section {
    padding-right: 20px !important;
}

.approve-reject-label {
    padding: 20px 0px 0px 20px;
}

.reject-section {
    padding-bottom: 10px;
}

.approve-reject-content {
    padding: 20px 0px 0px 20px;
}

.review-text {

    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.58;
    letter-spacing: normal;
    color: #4d5161;
}

.review-label {
    font-size: 14px;
    font-weight: bold !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4d5161;
}

@media screen and (min-width: 1200px) {
    .page-details {
        padding: 1rem 3rem 0px !important;
    }
}

.row.page-title {
    height: 55px;
    background-color: #eef3f5 !important;
}

.row.page-title>label {
    padding: 14px 0px 14px 55px !important;
    font-family: "Open Sans";
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.81px;
    color: #4d5161;
    text-transform: none !important;
}

.review-component>div:first-child {
    box-shadow: 0 1px 3px 0 rgb(9 30 66 / 12%) !important;
    border: solid 1px rgba(239, 242, 244, 0.2) !important;
    border-right: none;
    border-radius: 8px 0px 0px 8px;
    background-color: #ffffff;
}

.page-details.row.custom-card {
    /* margin-left: 30px;
    margin-right: 30px; */
    border-radius: 8px;
    box-shadow: 0 1px 3px 0 rgba(9, 30, 66, 0.12);
    border: solid 1px rgba(239, 242, 244, 0.2);
    background-color: #ffff !important;
}

.approve-only-section>.top-section:first-child {
    /* opacity: 0.2; */
    border-bottom: solid 1px rgba(151, 151, 151, 0.2);
    padding-bottom: 15px;
}

.approve-only-section>.top-section:nth-child(2) {
    /* opacity: 0.2; */
    border-bottom: solid 1px rgba(151, 151, 151, 0.2);
    padding-bottom: 0px;
}

/* .approve-only-section {
    padding-left: 30px;
    padding-right: 30px;

} */
.top-section h4,
.top-section>div:first-child {
    padding-left: 0px;
}

.row.page-title {
    background-color: transparent !important;
}

.redirect-home {
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.75;
    letter-spacing: normal;
    color: #00a1d2;
    margin-left: 12px !important;
}

.redirect-home img {
    vertical-align: baseline;
}

.btn-stacked {
    padding-bottom: 20px;
}
</style>
